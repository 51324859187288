body {
  padding: 0;
  margin: 0;
}
#gallery {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  max-height: 600px;
}
video {
  height: 600px;
  width: 100%;
  object-fit: cover;
}
.prevNext {
  cursor: pointer;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.5);
  border-style: none;
  z-index: 100;
}
.prevNext:hover:enabled {
  background-color: rgba(255, 255, 255, 0.75);
}
.previous {
  position: absolute;
  top: 270px;
  left: 0px;
}
.next {
  position: absolute;
  top: 270px;
  right: 0px;
}
.products {
  position: absolute;
  top: 0px;
  // Display is set to block in code after video plays
  display: none;
  // Scrolling is handled with absolute positioning and <> navigation, hide any scrollbars
  white-space: nowrap;
}
.productsWrapper {
  position: absolute;
  left: 0;
  top: 0;
  // Fade in is accomplished on the wrapper (after a video plays) by setting the opacity and using transition-property
  transition: transform 1s;
  transition-property: opacity;
  opacity: 0;
}
.productVideo {
  position: absolute;
  left: 0;
  top: -40px;
  width: 640px;
  height: 640px;
  transition: transform 1s;
  transition-property: opacity;
}

.productImage {
  position: absolute;
  max-width: 200px;
  transition: transform 1s;
  cursor: pointer;
  // -webkit-box-reflect: below 40px
  //   linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
}
// .productImageZoomed {
//   transform: scale(125%);
// }
.productImage:hover {
  transform: scale(110%);
  display: inline;
}
.productName {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  font-family: 'Roboto Slab';
  font-size: 22px;
  color: white;
  // Leaving the alpha set to 0 in case they change their mind
  background-color: rgba(0, 0, 0, 0);
  //min-height: 40px;
  text-align: center;
  //margin-top: -10px;
  padding: 0;
  transition-property: alpha;
  opacity: 0;
  padding-top: 10px;
  z-index: 200;
}
body {
  background-color: black;
}
